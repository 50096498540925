<template>
    <div class="header">
        <div class="header__logo">
          <img class="header__logo-image" src="../assets/zpu-logo.svg"
               alt="Die Digitale PrivatKopie Logo">
        </div>
        <div class="header__items">
          <HeaderItem
              v-for="item in content"
              :key="item.title"
              :section="item.section"
              :title="item.title"
              @scrollEvent="linkClick(item.section)"
          />
        </div>
        <div class="header__items--mobile">
          <button class="header__menu-button navbar-toggler navbar-light" type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu">
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
          </button>

          <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasMenu"
               aria-labelledby="offcanvasMenuLabel">
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="offcanvasMenuLabel">Menu</h5>
              <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                      aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
              <button class="offcanvas__link header__item" data-bs-dismiss="offcanvas"
                 v-for="(item,index) in items" :key="index"
                 @click="linkClick(item.section)">
                {{ item.title }}
              </button>
            </div>
          </div>
        </div>
    </div>
</template>
<script setup>
import { defineProps } from 'vue';
import HeaderItem from './Elements/HeaderItem.vue';

const emit = defineEmits('scrollEvent');

defineProps({
  content: Object,
});

const items = [
  { title: 'Nutzende', section: 'nutzerinnen' },
  { title: 'Kreative', section: 'kreative' },
  { title: 'Unsere Forderungen', section: 'unsereforderungen' },
];

function linkClick(section) {
  emit('scrollEvent', (section));
}

</script>
