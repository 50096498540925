<template>
  <div id="carouselExampleIndicators" class="video-slider carousel slide" data-bs-ride="carousel"
       data-bs-interval="15000000">
    <div class="carousel-indicators">
      <div class="carousel-indicator-border">
      <button type="button" data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"
              @click="mutePreviousVideo"></button>
      </div>
      <div class="carousel-indicator-border">
      <button type="button" data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1" aria-label="Slide 2"
              @click="mutePreviousVideo"></button>
      </div>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active" @change="checkVideo">
        <video class="video-slider__video"
        loop playsinline autoplay muted>
          <source src="../assets/videos/zpu-musican.mp4" type="video/mp4">
          <track kind="captions" />
        </video>
      </div>
      <div class="carousel-item" @change="checkVideo">
        <video class="video-slider__video"
                playsinline loop muted autoplay>
          <source src="../assets/videos/zpu-airplane.mp4" type="video/mp4">
          <track kind="captions" />
        </video>
      </div>
    </div>
    <button class="sound-button unmute-button" @click="unmuteVideo" ref="unmuteBtn">
      <img src="../assets/unmute.png" alt="muteBtn" class="sound-icon">
    </button>
    <button class="sound-button mute-button" @click="unmuteVideo" ref="muteBtn">
      <img src="../assets/mute.png" alt="muteBtn" class="sound-icon">
    </button>
    <button class="carousel-control-prev" type="button"
            data-bs-target="#carouselExampleIndicators" data-bs-slide="prev"
            @click="mutePreviousVideo">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button"
            data-bs-target="#carouselExampleIndicators" data-bs-slide="next"
            @click="mutePreviousVideo">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>

</template>
<script setup>
import { ref } from 'vue';

const muteBtn = ref(null);
const unmuteBtn = ref(null);

function showElement(element, visible) {
  if (visible) {
    // eslint-disable-next-line no-param-reassign
    element.style.display = 'block';
  } else {
    // eslint-disable-next-line no-param-reassign
    element.style.display = 'none';
  }
}

function mutePreviousVideo() {
  const previousVideo = document.querySelector('.video-slider .carousel-item.active video');
  previousVideo.muted = true;
  showElement(muteBtn.value, false);
  showElement(unmuteBtn.value, true);
}
function unmuteVideo() {
  const currentVideo = document.querySelector('.video-slider .carousel-item.active video');
  if (currentVideo.muted) {
    currentVideo.muted = false;
    showElement(muteBtn.value, true);
    showElement(unmuteBtn.value, false);
  } else {
    currentVideo.muted = true;
    showElement(muteBtn.value, false);
    showElement(unmuteBtn.value, true);
  }
}

</script>
