<template>
  <button class="header__item"
  @click="linkClick">{{ title }}</button>
</template>

<script setup>
import { defineProps } from 'vue';

const emit = defineEmits(['scrollEvent']);

defineProps({
  section: String,
  title: String,
});

function linkClick() {
  emit('scrollEvent');
}
</script>
