<template>
  <div class="backdrop">
    <div class="backdrop__panel">
      <div class="backdrop__image-container">
        <img src="../assets/zpu-logo.svg" alt="Die Digitale PrivatKopie Logo"
             class="backdrop__image">
      </div>
      <div class="backdrop__input-container">
        <label for="sitePassword" class="backdrop__input-label">
          <input type="password" name="sitePassword" id="sitePassword" class="backdrop__input"
                 ref="password" placeholder="Passwort">
          <p v-if="errorMessage" class="backdrop__error-message">
            Falsches Passwort</p>
        </label>
        <button class="backdrop__button" @click="comparePasswords">Enter</button>
      </div>
    </div>
  </div>
  <div class="backdrop__overlay"></div>
</template>
<script setup>
import { ref } from 'vue';

const emit = defineEmits(['check-pass']);
const password = ref(null);
const errorMessage = ref(false);

function comparePasswords() {
  let inputValue = password.value.value;
  inputValue = inputValue.trim().toLowerCase();
  if (inputValue === '13_pass_zpu_wort_31') {
    sessionStorage.setItem('zpu_pass', 1);
    emit('check-pass');
  } else {
    errorMessage.value = true;
  }
}

</script>
