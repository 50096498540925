<template>
  <BackDrop v-if="showBackdrop" @check-pass="checkIfPasswordHasBeenSet"/>
  <div class="main" v-else>
    <HeaderComponent @scrollEvent="scrollToElement" :content="content.items"/>
    <VideoSlider/>
    <TextImage :contentText="content.textImageFirst" :color="'gray'" :textAlign="'left'"
               id="nutzerinnen"/>
    <TextImage :contentText="content.textImageSecond" :color="'white'" :textAlign="'right'"
               id="kreative"/>
    <TextThreeColumns :content="content.textThreeColsContent" id="unsereforderungen"/>
    <ImageSlider/>
    <AboutUs :content="content.AboutUsContent"/>
    <FooterComponent/>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import data from './data/data.json';
import HeaderComponent from './components/HeaderComponent.vue';
import VideoSlider from './components/VideoSlider.vue';
import TextImage from './components/TextImage.vue';
import TextThreeColumns from './components/TextThreeColumns.vue';
import ImageSlider from './components/ImageSlider.vue';
import AboutUs from './components/AboutUs.vue';
import BackDrop from './components/BackDrop.vue';
import FooterComponent from './components/FooterComponent.vue';

const content = data;
const showBackdrop = ref(false);

function scrollToElement(event) {
  const element = document.querySelector(`#${event}`);
  setTimeout(() => {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, 500);
}

function getPasswordFromStorage() {
  return sessionStorage.getItem('zpu_pass');
}

function checkIfPasswordHasBeenSet() {
  const isPassSet = getPasswordFromStorage();
  if (isPassSet) {
    showBackdrop.value = false;
  }
}
// to enable backdrop asking for password
// onMounted(() => {
//   checkIfPasswordHasBeenSet();
// });

</script>
<style lang="scss">
  @import './scss/main';
</style>
