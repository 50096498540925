<template>
  <div class="image-slider">
    <div id="carouselImageIndicators" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <div class="carousel-indicator-border">
          <button type="button" data-bs-target="#carouselImageIndicators"
                  data-bs-slide-to="0" class="active"
                  aria-current="true"
                  aria-label="Slide 1"></button>
        </div>
        <div class="carousel-indicator-border">
          <button type="button" data-bs-target="#carouselImageIndicators"
                  data-bs-slide-to="1"
                  aria-current="true"
                  aria-label="Slide 2"></button>
        </div>
        <div class="carousel-indicator-border">
          <button type="button" data-bs-target="#carouselImageIndicators"
                  data-bs-slide-to="2"
                  aria-current="true"
                  aria-label="Slide 3"></button>
        </div>
        <div class="carousel-indicator-border">
          <button type="button" data-bs-target="#carouselImageIndicators"
                  data-bs-slide-to="3"
                  aria-current="true"
                  aria-label="Slide 4"></button>
        </div>
        <div class="carousel-indicator-border">
          <button type="button" data-bs-target="#carouselImageIndicators"
                  data-bs-slide-to="4"
                  aria-current="true"
                  aria-label="Slide 5"></button>
        </div>
        <div class="carousel-indicator-border">
          <button type="button" data-bs-target="#carouselImageIndicators"
                  data-bs-slide-to="5"
                  aria-current="true"
                  aria-label="Slide 6"></button>
        </div>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item" v-for="(slide, index, k) in slides" :key="index"
             :class="{ 'active': isFirstSlide(k) }">
          <img :src="getImgUrl(slide.desktop)"
               class="image-slider__image image-slider__image--desktop w-100" alt="...">
          <img :src="getImgUrl(slide.mobile)"
               class="image-slider__image image-slider__image--mobile  w-100" alt="...">
        </div>
      </div>
      <button class="carousel-control-prev" type="button"
              data-bs-target="#carouselImageIndicators" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button"
              data-bs-target="#carouselImageIndicators" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>
<script setup>
const slides = {
  first: {
    desktop: 'slide-1.jpg',
    mobile: 'slide-1.jpg',
  },
  second: {
    desktop: 'slide-2.jpg',
    mobile: 'slide-2.jpg',
  },
  third: {
    desktop: 'slide-3.jpg',
    mobile: 'slide-3.jpg',
  },
  fourth: {
    desktop: 'slide-4.jpg',
    mobile: 'slide-4.jpg',
  },
  fifth: {
    desktop: 'slide-5.jpg',
    mobile: 'slide-5.jpg',
  },
  sixth: {
    desktop: 'slide-6.jpg',
    mobile: 'slide-6.jpg',
  },
};

function isFirstSlide(index) {
  return index === 0;
}

function getImgUrl(pic) {
  // eslint-disable-next-line global-require,import/no-dynamic-require
  return require(`../assets/images/${pic}`);
}
</script>
