<template>
  <div class="text-image" :class="{ 'text-image--gray': isGray }" :id="id">
    <div class="text-image__content"  :class="{ 'text-image__content--reversed': textOnRight }">
      <div class="text-image__text-container">
        <h2 class="text-image__title" v-html="contentText.title"></h2>
        <p class="text-image__description" v-for="(text,index) in contentText.description"
           :key="index">
          <span v-html="text"></span>
        </p>
      </div>
      <div class="text-image__image-container">
        <img v-if="isFirstSection(id)" class="text-image__image"
             src="@/assets/images/infografik_nutzungsformen.png" alt="">
        <img v-else class="text-image__image"
             src="@/assets/images/infografik_verguetungssystem.png" alt="">
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  id: String,
  color: String,
  'text-align': String,
  contentText: Object,
});

function isFirstSection(id) {
  return id === 'nutzerinnen';
}

const isGray = props.color === 'gray' ? 1 : 0;
const textOnRight = props.textAlign === 'right' ? 1 : 0;
</script>
